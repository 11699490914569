var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pa-3", attrs: { align: "left", justify: "center" } },
    [
      _c("Loading", {
        attrs: { active: _vm.loadingInicial, "is-full-page": true }
      }),
      _c(
        "v-card",
        { attrs: { align: "left", justify: "left" } },
        [
          _c(
            "v-card-text",
            { staticClass: "pa-3 font-weight-light white black--text" },
            [
              _c("v-icon", { staticClass: "primary--text lighten-2 pa-2" }, [
                _vm._v(" mdi-chart-box ")
              ]),
              _vm._v("Relatórios > Mensageria > Campanhas SMS ")
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-card",
        {},
        [
          _c(
            "v-row",
            { staticClass: "mx-3 mt-3 py-4", attrs: { align: "center" } },
            [
              _c(
                "v-col",
                { staticClass: "px-1 py-3", attrs: { lg: "12" } },
                [
                  _c(
                    "v-card-text",
                    { staticClass: "pa-0" },
                    [
                      _c("v-icon", { staticClass: "mx-2" }, [
                        _vm._v("mdi-information-outline")
                      ]),
                      _vm._v(
                        "Utilize os campos abaixo para aplicar filtros na pesquisa"
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "4", xs: "2", sm: "2", md: "3", lg: "3" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      clearable: "",
                      solo: "",
                      small: "",
                      "prepend-icon": "mdi-chevron-right",
                      label: "Id da Campanha",
                      placeholder: "Id da Campanha",
                      elevation: "0",
                      "hide-details": "auto"
                    },
                    on: {
                      keydown: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.filtrar()
                      }
                    },
                    model: {
                      value: _vm.filtros.id,
                      callback: function($$v) {
                        _vm.$set(_vm.filtros, "id", $$v)
                      },
                      expression: "filtros.id"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "4", xs: "2", sm: "2", md: "3", lg: "3" } },
                [
                  _c("v-select", {
                    attrs: {
                      clearable: "",
                      solo: "",
                      small: "",
                      items: _vm.status_campanha_items,
                      "item-text": "label",
                      "item-value": "value",
                      "prepend-icon": "mdi-chevron-right",
                      label: "Status da Campanha",
                      placeholder: "Status da Campanha",
                      elevation: "0",
                      "hide-details": "auto"
                    },
                    on: {
                      keydown: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.filtrar()
                      }
                    },
                    model: {
                      value: _vm.filtros.is_active,
                      callback: function($$v) {
                        _vm.$set(_vm.filtros, "is_active", $$v)
                      },
                      expression: "filtros.is_active"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "4", xs: "2", sm: "2", md: "3", lg: "3" } },
                [
                  _c("v-select", {
                    attrs: {
                      clearable: "",
                      solo: "",
                      small: "",
                      items: _vm.tipo_campanha_items,
                      "item-text": "label",
                      "item-value": "value",
                      "prepend-icon": "mdi-chevron-right",
                      label: "Tipo da Campanha",
                      placeholder: "Tipo da Campanha",
                      elevation: "0",
                      "hide-details": "auto"
                    },
                    on: {
                      keydown: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.filtrar()
                      }
                    },
                    model: {
                      value: _vm.filtros.campaign_type,
                      callback: function($$v) {
                        _vm.$set(_vm.filtros, "campaign_type", $$v)
                      },
                      expression: "filtros.campaign_type"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                {
                  staticClass: "d-flex flex-row",
                  attrs: { cols: "4", xs: "2", sm: "2", md: "3", lg: "3" }
                },
                [
                  _c("v-autocomplete", {
                    attrs: {
                      solo: "",
                      multiple: "",
                      small: "",
                      items: _vm.listaSituacoes,
                      label: "Status do Título",
                      placeholder: "Status do Título",
                      "item-text": "nm_status",
                      "item-value": "cd_status",
                      "prepend-icon": "mdi-chevron-right",
                      clearable: "",
                      "hide-details": "auto",
                      disabled: _vm.desabilitarStatusTitulo(),
                      "data-cy": "statusTitulo"
                    },
                    model: {
                      value: _vm.filtros.title_status,
                      callback: function($$v) {
                        _vm.$set(_vm.filtros, "title_status", $$v)
                      },
                      expression: "filtros.title_status"
                    }
                  }),
                  _vm.desabilitarStatusTitulo()
                    ? _c(
                        "v-tooltip",
                        {
                          attrs: { top: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function({ on, attrs }) {
                                  return [
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "ma-auto ml-2",
                                        attrs: {
                                          fab: "",
                                          "x-small": "",
                                          elevation: "0"
                                        }
                                      },
                                      [
                                        _c(
                                          "v-icon",
                                          _vm._g(
                                            _vm._b(
                                              { attrs: { color: "grey" } },
                                              "v-icon",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [_vm._v(" mdi-information-outline ")]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            783001228
                          )
                        },
                        [
                          _c("span", [
                            _vm._v(
                              'Selecione Tipo da Campanha "Títulos" para habilitar busca por Status'
                            )
                          ])
                        ]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "mx-3 mt-3 py-4", attrs: { align: "center" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "4", xs: "2", sm: "2", md: "3", lg: "3" } },
                [
                  _c(
                    "v-menu",
                    {
                      attrs: {
                        solo: "",
                        "close-on-content-click": false,
                        "nudge-right": 40,
                        transition: "scale-transition",
                        "offset-y": "",
                        "max-width": "290px",
                        "min-width": "290px"
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function({ on }) {
                            return [
                              _c(
                                "v-text-field",
                                _vm._g(
                                  {
                                    attrs: {
                                      clearable: "",
                                      solo: "",
                                      "hide-details": "auto",
                                      value: _vm.dataEnvioInicialFormatada,
                                      label: "Data Inicial",
                                      "prepend-icon": "mdi-calendar-cursor",
                                      "persistent-hint": "",
                                      readonly: ""
                                    },
                                    on: {
                                      "click:clear": function($event) {
                                        return _vm.limparFiltroData(
                                          "send_date_from"
                                        )
                                      }
                                    }
                                  },
                                  on
                                )
                              )
                            ]
                          }
                        }
                      ]),
                      model: {
                        value: _vm.dataEnvioInicial,
                        callback: function($$v) {
                          _vm.dataEnvioInicial = $$v
                        },
                        expression: "dataEnvioInicial"
                      }
                    },
                    [
                      _c("v-date-picker", {
                        attrs: {
                          "selected-items-text": "Datas Selecionadas",
                          color: "primary",
                          locale: "pt-br"
                        },
                        model: {
                          value: _vm.filtros.send_date_from,
                          callback: function($$v) {
                            _vm.$set(_vm.filtros, "send_date_from", $$v)
                          },
                          expression: "filtros.send_date_from"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "4", xs: "2", sm: "2", md: "3", lg: "3" } },
                [
                  _c(
                    "v-menu",
                    {
                      attrs: {
                        solo: "",
                        "close-on-content-click": false,
                        "nudge-right": 40,
                        transition: "scale-transition",
                        "offset-y": "",
                        "max-width": "290px",
                        "min-width": "290px"
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function({ on }) {
                            return [
                              _c(
                                "v-text-field",
                                _vm._g(
                                  {
                                    attrs: {
                                      clearable: "",
                                      solo: "",
                                      "hide-details": "auto",
                                      value: _vm.dataEnvioFinalFormatada,
                                      label: "Data Final",
                                      "prepend-icon": "mdi-calendar-cursor",
                                      "persistent-hint": "",
                                      readonly: ""
                                    },
                                    on: {
                                      "click:clear": function($event) {
                                        return _vm.limparFiltroData(
                                          "send_date_to"
                                        )
                                      }
                                    }
                                  },
                                  on
                                )
                              )
                            ]
                          }
                        }
                      ]),
                      model: {
                        value: _vm.dataEnvioFinal,
                        callback: function($$v) {
                          _vm.dataEnvioFinal = $$v
                        },
                        expression: "dataEnvioFinal"
                      }
                    },
                    [
                      _c("v-date-picker", {
                        attrs: {
                          "selected-items-text": "Datas Selecionadas",
                          color: "primary",
                          locale: "pt-br"
                        },
                        model: {
                          value: _vm.filtros.send_date_to,
                          callback: function($$v) {
                            _vm.$set(_vm.filtros, "send_date_to", $$v)
                          },
                          expression: "filtros.send_date_to"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            {
              staticClass: "mx-3 mt-3 py-4",
              attrs: { align: "center", justify: "end" }
            },
            [
              _c(
                "v-btn",
                {
                  staticClass: "mr-3",
                  attrs: {
                    color: "primary",
                    disabled: false,
                    loading: _vm.loadingFilter
                  },
                  on: {
                    click: function($event) {
                      return _vm.filtrar()
                    }
                  }
                },
                [
                  _vm._v(" Buscar Resultados "),
                  _c("v-icon", { staticClass: "ml-2" }, [_vm._v("mdi-magnify")])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-card",
        { staticClass: "mt-6" },
        [
          _c("v-data-table", {
            staticClass: "elevation-1 pa-3",
            attrs: {
              headers: _vm.headers,
              items: _vm.resultadosCampanhasSMS,
              "footer-props": {
                itemsPerPageOptions: [10, 50, 100, 500, -1],
                itemsPerPageText: "Itens por página:"
              },
              loading: _vm.loadingTable,
              "loading-text": "Pesquisando Campanhas...",
              "no-data-text":
                "Nenhum resultado encontrado... Faça uma pesquisa",
              "no-results-text": "Nenhum resultado encontrado..."
            },
            scopedSlots: _vm._u(
              [
                {
                  key: `header.enviados_total`,
                  fn: function({ header }) {
                    return [
                      _c("span", [_vm._v(_vm._s(header.text))]),
                      _c(
                        "v-tooltip",
                        {
                          attrs: { top: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function({ on }) {
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g({ attrs: { icon: "" } }, on),
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            attrs: { color: "grey-lighten-1" }
                                          },
                                          [_vm._v(" mdi-help-circle-outline ")]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        },
                        [
                          _c("span", [
                            _vm._v("Total de envios realizados pela campanha")
                          ])
                        ]
                      )
                    ]
                  }
                },
                {
                  key: `header.enviados_parcial`,
                  fn: function({ header }) {
                    return [
                      _c("span", [_vm._v(_vm._s(header.text))]),
                      _c(
                        "v-tooltip",
                        {
                          attrs: { top: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function({ on }) {
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g({ attrs: { icon: "" } }, on),
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            attrs: { color: "grey-lighten-1" }
                                          },
                                          [_vm._v(" mdi-help-circle-outline ")]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        },
                        [
                          _c("span", [
                            _vm._v(
                              "Envios realizados dentro de um recorte de Data ou Status de Título (pode variar com relação aos envios totais)"
                            )
                          ])
                        ]
                      )
                    ]
                  }
                },
                {
                  key: `item.id`,
                  fn: function({ item }) {
                    return [_c("span", [_vm._v(" " + _vm._s(item.id) + " ")])]
                  }
                },
                {
                  key: `item.title`,
                  fn: function({ item }) {
                    return [
                      _c("span", [_vm._v(" " + _vm._s(item.title) + " ")])
                    ]
                  }
                },
                {
                  key: `item.send_date`,
                  fn: function({ item }) {
                    return [
                      _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(_vm.formatarDataHoraMoment(item.send_date)) +
                            " "
                        )
                      ])
                    ]
                  }
                },
                {
                  key: `item.created_by_title`,
                  fn: function({ item }) {
                    return [
                      item.created_by_title == true
                        ? _c("span", [_vm._v(_vm._s("Título"))])
                        : _c("span", [_vm._v(_vm._s("Contatos"))])
                    ]
                  }
                },
                {
                  key: `item.enviados_total`,
                  fn: function({ item }) {
                    return [
                      item.created_by_title == true
                        ? _c("span", [
                            _vm._v(_vm._s(item.sent_title_submissions))
                          ])
                        : _c("span", [_vm._v(_vm._s(item.sent_contacts))])
                    ]
                  }
                },
                {
                  key: `item.enviados_parcial`,
                  fn: function({ item }) {
                    return [_vm._v(" " + _vm._s(item.submissions) + " ")]
                  }
                },
                {
                  key: `item.contatos`,
                  fn: function({ item }) {
                    return [
                      item.created_by_title == true
                        ? _c("span", [
                            _vm._v(_vm._s(item.all_title_submissions))
                          ])
                        : _c("span", [_vm._v(_vm._s(item.all_contacts))])
                    ]
                  }
                },
                {
                  key: `item.message`,
                  fn: function({ item }) {
                    return [
                      item.message.length < 8
                        ? _c("span", [_vm._v(_vm._s(item.message))])
                        : _vm._e(),
                      item.message.length > 8
                        ? _c("span", [
                            _vm._v(_vm._s(item.message.substring(0, 60) + ".."))
                          ])
                        : _vm._e()
                    ]
                  }
                },
                {
                  key: `item.is_active`,
                  fn: function({ item }) {
                    return [
                      _c("v-switch", {
                        style: {
                          color: item.is_active != "" ? "green" : "red"
                        },
                        attrs: {
                          color: item.is_active != "" ? "green" : "red",
                          readonly: "",
                          inset: ""
                        },
                        model: {
                          value: item.is_active,
                          callback: function($$v) {
                            _vm.$set(item, "is_active", $$v)
                          },
                          expression: "item.is_active"
                        }
                      })
                    ]
                  }
                }
              ],
              null,
              true
            )
          })
        ],
        1
      ),
      _c(
        "v-card",
        { staticClass: "mt-3" },
        [
          _c(
            "v-row",
            { staticClass: "my-4 ml-4" },
            [
              _c(
                "v-col",
                { staticClass: "text-end", attrs: { lg: "12" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mx-6",
                      attrs: {
                        color: "primary",
                        disabled: "",
                        loading: _vm.loadingExport
                      },
                      on: {
                        click: function($event) {
                          return _vm.exportar()
                        }
                      }
                    },
                    [
                      _vm._v(" Exportar resultados "),
                      _c(
                        "v-icon",
                        { staticClass: "ma-2", attrs: { small: "" } },
                        [_vm._v("cloud_download")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.snackbarDialog
        ? _c("Snackbar", {
            attrs: {
              mostrarSnackbar: _vm.snackbarDialog,
              corSnackbar: _vm.snackbarColor,
              mensagemSnackbar: _vm.mensagemRetornoSnackbar
            },
            on: {
              fecharSnackbar: function($event) {
                _vm.snackbarDialog = false
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }